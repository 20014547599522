*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
}
body{
  margin:0;
  padding:0;
}
.container{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}