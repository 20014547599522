div {
    width: 100%;
    margin: 0;
}

.wholefoot {
    background-color: gray;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
}

.foot {
    width: 40%;
    padding: 10px;
    text-align: center;
}

.company {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 30px;
}

.companyinfo {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.companyinfo h2 {
    font-size: 15px;
    color: #000;
}

.footdesc {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    padding: 10px;
    text-align: center;
    gap: 30px;
}

.center {
    display: flex;
    flex-direction: column;
}
.centerSocial{
    width:15%;
}

.center h1 {
    font-size: 20px;
    color: #000;
}

.center h2 {
    font-size: 15px;
    color: #000;
}
@media only screen and (min-device-width: 320px) and (max-width: 768px){
    .wholefoot{
        width: 100%;
        display: flex;
        flex-direction: column;
    }.company{
        display: flex;
        flex-direction: column;
    }
    .footdesc{
        width: 100%;
    }
    .footdesc{
        width:100%;
        
        display: flex;
        flex-direction: column;
    }
    .centerSocial{
        display: flex;
        flex-direction: column;
        align-self: center;
    }
  
           
}