@import url('https://fonts.googleapis.com/css2?family=Comforter+Brush&family=Inter:wght@100..900&display=swap');


*{
    box-sizing: border-box;
    margin: 0;
    padding:0;
}

.comforter-brush-regular {
    font-family: "Comforter Brush", cursive;
    font-weight: 400;
    font-style: normal;
  }

.studioImg{
    align-content:flex-start;
   background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://live.staticflickr.com/65535/53695180703_feda264282_k.jpg");
    background-position: center;  
    padding-bottom: 15px;
    text-align: center;
    height: 100vh;
}


.topPage{
    
   padding-top: 25vh;
    height:50vh;
    text-align: center;
    align-items: center;
  justify-content: center;
}
.column{
    position: static;
    width: 100%;
    gap: 5%;
    display: flex;
    flex-direction: row;
    float: left;
    align-items: center;  
    justify-content: center;
}
.box h1{
    font-family: "comforter brush";
    font-size: small;
    color:rgb(84, 24, 24);
}
.rightbox  h2 {
    font-family: "comforter brush";
    font-size: large;
    color:rgb(84, 24, 24);
}
.leftbox h2 {
    font-family: "comforter brush";
    font-size: large;
    color:rgb(84, 24, 24);
}

.rightbox  h2 b {
    font-family: "comforter brush";
    font-size: larger;
    color:rgb(84, 24, 24);
}
.leftbox h2 b{
    font-family: "comforter brush";
    font-size: larger;
    color:rgb(84, 24, 24);
}
.box p {
    font-family: "comforter brush";
    font-size: medium;
    color:rgb(84, 24, 24);
}
.rightbox{
 
    align-items: center;
    text-align: center;
    background-color:bisque;
    border-radius: 5%;
    box-sizing: content-box;
    border:5px solid ;
    border-color:rgb(84, 24, 24);
    opacity: 50%;

   width: 30vw;
height:28vh;
   padding:2%;
}
.leftbox{
 
    align-items: center;
    text-align: center;
    background-color:bisque;
    border-radius: 5%;
    box-sizing: content-box;
    border:5px solid ;
    border-color:rgb(84, 24, 24);
    opacity: 50%;

   width: 30vw;
height:28vh;
   padding:2%;
}
.upcomingEvents h2{
    padding: .5rem;
    font-family: "comforter brush";
    font-size: 2rem;
}
hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
  }
  hr.gradient {
    height: 4px;
    border: none;
    border-radius: 6px;
    background: linear-gradient(
      90deg,
      rgb(0, 0, 0) 0%,
      rgb(98, 98, 104) 21%,
      rgb(188, 190, 192) 51%,
      rgb(11, 16, 13) 100%
    );
  }
.divider{
   
    height: 1vh;
    background-color: black;
}
.exhibdivider{
    position: absolute;
    right: 37vw;
    height: .5vh;
    width: 25%;
    background-color: black;
}

.pastEvents{
    background-color: lightgray;
    width: 25%;
    position: absolute;
    right:37vw;
    height:10vh ;
    padding: 5rem;
    text-align: center;
    align-items:center ;
    justify-content: center;

 }
.pastEvents h2{
    padding: 0.5rem;
    font-family: "comforter brush";
    font-size: 2rem;
    color:rgb(84, 24, 24);
}
.textBackground{
    
    padding: 0;
    margin:0;
    background-color: #fff;
    justify-content: center;

    
    
}
.fibersAndFabrics h3{
    margin: auto;
    width: 50%;
    border: 3px solid transparent;
    padding: 10px;

    text-align: center;
    align-items: center;
    justify-content: center;
}
.fibersAndFabrics{
 

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 7%;
    }
    .theArtofIllustration h3{
        margin: auto;
        width: 50%;
        border: 3px solid transparent;
        padding: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.theArtofIllustration{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 7%;
}
.ikebanaSliderData h3{
    margin: auto;
    width: 50%;
    border: 3px solid transparent;
    padding: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.ikebanaSliderData{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 7%;
}
.summerBlues h3{
    margin: auto;
    width: 50%;
    border: 3px solid transparent;
    padding: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.summerBlues{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 7%;
}
.barnsAndBridges h3 {
    margin: auto;
    width: 50%;
    border: 3px solid transparent;
    padding: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.barnsAndBridges{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
    padding: 7%;
}
.winterWonder h3{
    margin: auto;
    width: 50%;
    border: 3px solid transparent;
    padding: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.winterWonder{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
    padding: 7%;
}
.FairhavenAndCross h3{
    margin: auto;
    width: 50%;
    border: 3px solid transparent;
    padding: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.FairhavenAndCross{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 7%;
}
.exhibit10x10 h3{
    margin: auto;
    width: 50%;
    border: 3px solid transparent;
    padding: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.exhibit10x10{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 7%;
}
.bottlesAndBrushes{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 7%;
}
.StudioArtistTour{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 7%;
}

.exhib{
    font-family: "comforter brush";
    display :flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    gap:10%;
    width:100%
}.exhibitions h2{
    font-family: "comforter brush";
    text-align: start;
    padding-left: 2%;
    font-size: 2rem;
}
.exhibitions{
    height: 10vh;;
}
.descbin p{
    font-family: "comforter brush";
    font-size:x-large;
    color:rgb(84, 24, 24);
}
.descbin h2{
    font-family: "comforter brush";
    font-size: large;
    color:rgb(84, 24, 24);
}
.descbin{
    background-color: lightgrey;
    align-items:center;
    padding-bottom: 10%;



}
.bottomPage p{
    font-family: "comforter brush";
    font-size: larger;
    color:rgb(84, 24, 24);
}

.bottomPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: lightgray;
    font-family: "comforter brush";
    padding-left: 10%;
    padding-right: 10%;  
  }
  .social{
    width:15%;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    gap: 8px;
  }
  .button1{
    font-family: "comforter brush";
    width:15%;
    color: #7b3c3c;
    border:none;
    background-color: darkgrey;
    border-radius: 10%;;
    padding:20px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size:1.6rem;
    margin: 4px 2px;
    cursor: pointer;

    
   }
div {
    width: 100%;
    margin: 0;
}


h5 {
    font-size: 10px;
}
h6{ 
    text-align: center;
    justify-content: center;
    font-family: "comforter brush";
    font-size: 75px;
    color: rgb(84, 24, 24);
}
ul {
    font-size: 30px;
}
h4 { 
    font-family: "comforter brush";
    font-size: 50px;
    color: rgb(84, 24, 24);
}
b {    font-family: "comforter brush";
    font-size: xx-large;
    color: rgb(84, 24, 24);

}
 p {
    font-family: "comforter brush";
    font-size:6rem ;
    color: rgb(84, 24, 24);
}
h1 {
    font-size: larger;
    font-family: "comforter brush";
    color: rgb(84, 24, 24);
}
h3 {
    font-size:1.5rem;
    color: rgb(84, 24, 24);
    font-family: "sans-serif";
}
h2 {
    font-size:small;
    color: rgb(84, 24, 24);
    font-family: "comforter brush";
}


@media only screen and (min-width: 320px) and (max-width: 768px){
    .studioImg{
        display: flex;
        flex-direction: column;
        width:100vw;
        height: 100vh;
    
    }
    .topPage{
     height: 50vh;
     
    }
     .topPage h4{
         font-size: large;
     }
     .topPage h1{
         font-size: small;
     }
     
     .column{
        display: flex;
        flex-direction: column;
        align-self: center;
        height:45vh ;
         gap: 5%;
         }
 
     .rightbox h2{
        font-size: 0.5rem;
     }
     .rightbox b {
        font-size: .8rem;
     }
     .rightbox {
     
        width:50%;
         font-size: 0.5rem;
         padding: 2%;
     }
     .leftbox h2{
        font-size: 0.5rem;
     }
     .leftbox b {
        font-size: 0.8rem;
     }
     .leftbox {
  
        width:50%;
         font-size: 0.5rem;
         padding: 2%;
     }
  
     .upcomingEvents h2{
        font-size: medium;
     }
     .upcomingEvents {
        
            width: 100%;
         font-size:0.8rem;
     }
     .pastEvents h2{
        font-size: 0.8rem;
        
     }
     .textBackground{
        margin: 0;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        
     }
     .pastEvents{
        background-color: transparent;
        margin: 0;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
 padding: 2%;
 padding-bottom: 5%;
        font-size: 2rem; 
      
     }
     .descbin p {
         font-size: 10px;
     }
     .descbin{
            justify-content: center;
        padding: 2%;;
     }
       
    .exhib{
    
        width:100%;
    }  
     
     .exhibitions{
        width: 50%;
         font-size: 0.8rem;
     }
     .exhibitions h2{
        font-size: 1rem;
     }
     .bottomPage{
        width:100vw;
         font-size: 1rem;
     }
     .button1{
        width: auto;
     }
 .divider{
    width:100vw;
 }
 .social{
    align-self: center;
    display: flex;
    flex-direction:column ;
    align-items: center;
    gap:100%;
 }
 .facebookLogo{
    width: auto;
 }
 .instaLogo{
    width:auto;
 }
 }
 